import { api } from '@api/index';
import { ProductPageResponse } from '@api/types/generated';
import { getNextPage, mapper } from '@api/utils/pagenation';
import { SearchFilterCondition } from 'types/productSearch';
import { useInfiniteQuery } from '@tanstack/react-query';
import { itemsPerPage } from '@utils/product';
import { SearchedData } from 'types/products';

type Props = {
  categoryType: string;
  isSalesEnded: boolean;
  productCategoryName: string;
  isSearchResults: boolean;
  filterCondition: SearchFilterCondition;
};

export const useProductsApi = ({
  isSalesEnded,
  productCategoryName,
  isSearchResults,
  filterCondition,
}: Props) => {
  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    enabled: !isSearchResults,
    queryKey: ['products', isSalesEnded, productCategoryName],
    queryFn: ({ pageParam = 0 }) => {
      return api.getListApiV1ProductsGet({
        offset: pageParam,
        limit: itemsPerPage,
        detail: true,
        shape: true,
        category: [productCategoryName],
        sales_ended: !isSalesEnded,
        first_order: 'sales_date_order',
      });
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => getNextPage(lastPage.pager),
    select: (data) => {
      return {
        products: mapper(data?.pages, 'products'),
        pager: data.pages.at(0)?.pager ?? {},
      };
    },
    refetchOnWindowFocus: false,
  });

  const {
    data: searchedData,
    isLoading: isSearchLoading,
    isFetching: isSearchFetching,
    isFetchingNextPage: isSearchFetchingNextPage,
    hasNextPage: hasSearchNextPage,
    fetchNextPage: fetchSearchNextPage,
  } = useInfiniteQuery<ProductPageResponse, Error, SearchedData>({
    enabled: isSearchResults,
    queryKey: ['searched_products', filterCondition],
    queryFn: ({ pageParam = 0 }) => {
      return api.getListApiV1ProductsGet({
        offset: pageParam as number,
        limit: itemsPerPage,
        detail: true,
        shape: true,
        q: filterCondition.name ?? undefined,
        sales_ended: filterCondition.isSalesEnded
          ? undefined
          : filterCondition.isSalesEnded,
        tags: filterCondition.tags,
        first_order: 'sales_date_order',
        category: filterCondition.category
          ? filterCondition.category
          : undefined,
      });
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => getNextPage(lastPage.pager),
    select: (data) => {
      return {
        products: mapper(data?.pages, 'products'),
        pager: data.pages.at(0)?.pager ?? {},
      };
    },
    refetchOnWindowFocus: false,
  });

  return {
    data,
    searchedData,
    isLoadingNextPage:
      isLoading || isFetching || isFetchingNextPage || !hasNextPage,
    isLoadingSearchNextPage:
      isSearchLoading ||
      isSearchFetching ||
      isSearchFetchingNextPage ||
      !hasSearchNextPage,
    isSearchLoading,
    isLoading,
    isFetchingNextPage,
    isSearchFetchingNextPage,
    fetchNextPage,
    fetchSearchNextPage,
  };
};
