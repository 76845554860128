import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Typography,
} from '@mui/material';
import { getProfitTagName, productTags } from '@utils/const';
import { theme } from 'theme';
import { SearchFilterCondition } from 'types/productSearch';

type Props = {
  filterCondition: SearchFilterCondition;
  isSalesEnded: boolean;
  isUsingProducts: boolean;
  categoryOptions: { label: string; id: string }[];
  selectedCategory: string;
  selectedTags: string[];
  handleChangeSelectedCategory: (e: SelectChangeEvent) => void;
  handleChangeSelectedTag: (tag: string[]) => void;
  handleChangeSwitch: (key: keyof SearchFilterCondition) => void;
};

export const ProductsSearchFields = ({
  isSalesEnded,
  categoryOptions,
  selectedCategory = '全カテゴリ',
  selectedTags,
  handleChangeSelectedCategory,
  handleChangeSelectedTag,
  handleChangeSwitch,
}: Props) => {
  return (
    <Box component="div" display="flex" flexDirection="column">
      <Box
        component="div"
        display="flex"
        padding="16px"
        alignItems="center"
        gap="10px"
        alignSelf="stretch"
      >
        <Typography
          variant="subtitle1"
          fontWeight="700"
          fontSize="16px"
          color={theme.palette.textBlack.secondary}
        >
          詳細条件を指定
        </Typography>
      </Box>
      <Box
        component="div"
        display="flex"
        py="8px"
        px="16px"
        flexDirection="column"
        alignItems="flex-start"
        gap="8px"
        flex="1 0 0"
        alignSelf="stretch"
      >
        <Box
          component="div"
          display="flex"
          alignItems="center"
          pt="16px"
          position="relative"
          pb="44px"
          justifyContent="space-between"
          height="40px"
          gap="8px"
        >
          <Typography
            variant="body2"
            width="120px"
            color={theme.palette.textBlack.secondary}
          >
            カテゴリ
          </Typography>
          <Select
            onChange={(e) => handleChangeSelectedCategory(e)}
            value={selectedCategory}
            sx={{
              width: '350px',
              border: `1px solid ${theme.palette.dividerBlack.dark}`,
              height: '40px',
              p: '0',
            }}
          >
            {categoryOptions.map((category) => (
              <MenuItem value={category.label} key={category.id}>
                <Typography variant="body1">{category.label}</Typography>
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          component="div"
          display="flex"
          alignItems="center"
          pt="16px"
          position="relative"
          pb="44px"
          justifyContent="space-between"
          height="40px"
          gap="8px"
        >
          <Typography
            variant="body2"
            width="120px"
            color={theme.palette.textBlack.secondary}
          >
            商品属性
          </Typography>
          <FormControl sx={{ flex: 1, maxWidth: 160 }}>
            <InputLabel size="small">商品属性</InputLabel>
            <Select
              size="small"
              label="商品属性"
              multiple
              value={selectedTags}
              sx={{
                minWidth: '352px',
                border: `1px solid ${theme.palette.dividerBlack.dark}`,
              }}
              onChange={(e) =>
                handleChangeSelectedTag(e.target.value as string[])
              }
              renderValue={() =>
                selectedTags.map((tag) => getProfitTagName(tag)).join(',')
              }
            >
              <MenuItem
                divider
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  '&:hover': { backgroundColor: 'transparent' },
                }}
              >
                <Button
                  disabled={productTags.length === selectedTags.length}
                  onClick={() =>
                    handleChangeSelectedTag(
                      productTags.map((productTag) =>
                        getProfitTagName(productTag)
                      )
                    )
                  }
                >
                  すべて選択
                </Button>
                <Button
                  disabled={selectedTags?.length === 0}
                  onClick={() => handleChangeSelectedTag([])}
                >
                  すべて解除
                </Button>
              </MenuItem>
              {productTags.map((productTag, index) => {
                const checked = selectedTags.includes(
                  getProfitTagName(productTag)
                );
                return (
                  <MenuItem
                    divider
                    key={index}
                    value={getProfitTagName(productTag)}
                  >
                    <Checkbox checked={checked} />
                    {getProfitTagName(productTag)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Box
          component="div"
          display="flex"
          alignItems="center"
          pt="16px"
          position="relative"
          pb="44px"
          justifyContent="space-between"
          height="40px"
          gap="8px"
        >
          <Typography
            variant="body2"
            width="120px"
            color={theme.palette.textBlack.secondary}
          >
            推奨取消の商品
          </Typography>
          <Switch
            checked={isSalesEnded}
            onChange={() => handleChangeSwitch('isSalesEnded')}
          />
        </Box>
        {/* add after backend API is ready */}
        {/* <Box
          component="div"
          display="flex"
          alignItems="center"
          pt="16px"
          position="relative"
          pb="44px"
          justifyContent="space-between"
          height="40px"
          gap="8px"
        >
          <Typography
            variant="body2"
            width="120px"
            color={theme.palette.textBlack.secondary}
          >
            表示中の店舗で取扱いのない商品
          </Typography>
          <Switch
            checked={isUsingProducts}
            onChange={() => handleChangeSwitch("isUsingProducts")}
          />
        </Box> */}
      </Box>
    </Box>
  );
};
