import {
  BottomDrawer,
  HighestScoreCandidate,
  ProductCandidates,
  ProductChoiceCard,
  ProductChoiceDrawer,
  SelectedProduct,
  bottomDrawerHeight,
} from '@components/organisms/productCandidatesDrawer/fragments';
import { UnknownCard } from '@components/organisms/productCandidatesDrawer/fragments/unknownCard';
import { Box, Drawer } from '@mui/material';
import { useGetProductQuery } from '@reducers/shelfAppsApi';
import { FC } from 'react';
import { DataDogSourceType, Product } from 'types/common';
import { RealogramSelectedItem, ProductCandidate } from 'types/realogram';
import { HistoryCandidates } from './fragments/historyCandidates';
import { CandidateEmptyResult } from './fragments/candidateEmptyResult';

type Props = {
  open: boolean;
  products?: Product[];
  referenceImage: string;
  selectedItem?: RealogramSelectedItem;
  bottomDrawerOpen: boolean;
  primaryCandidate?: ProductCandidate;
  productCandidates?: ProductCandidate[];
  selectedCandidate?: ProductCandidate;
  historyCandidates?: Product[];
  productIdFrom: number | 'unknown';
  isUnknown: boolean;
  productCandidatesLength: number;
  selectedUnknown: boolean;
  isLoading: boolean;
  isResetData: boolean;
  selectedProductChoice: boolean;
  isDisplayProductDetail: boolean;
  handleOpenReferenceImagePreview: () => void;
  setSelectedProductZoomIn: (product?: Product) => void;
  handleClose: (
    sourceType: DataDogSourceType,
    productIdFrom: number | 'unknown',
    productIdTo: number | 'unknown'
  ) => void;
  reset: () => void;
  handleProductClick: () => void;
  handleProductChoiceClick: () => void;
  handleSpecialProductClick: () => void;
  handleChangeCandidate: () => Promise<void>;
  handleCloseProductChoice: () => void;
  setIsDisplayProductDetail: (isDisplayProductDetail: boolean) => void;
  setSelectedCandidate: (
    selectedCandidate: ProductCandidate | undefined
  ) => void;
  isLarger: boolean;
  realogramProductsCategories?: string[];
};

export const ProductCandidatesDrawer: FC<Props> = ({
  open,
  products,
  referenceImage,
  selectedItem,
  bottomDrawerOpen,
  primaryCandidate,
  productCandidates,
  selectedCandidate,
  historyCandidates,
  productIdFrom,
  isUnknown,
  productCandidatesLength,
  selectedUnknown,
  isDisplayProductDetail,
  isLoading,
  isResetData,
  selectedProductChoice,
  isLarger,
  realogramProductsCategories,
  handleOpenReferenceImagePreview,
  setSelectedProductZoomIn,
  handleClose,
  reset,
  handleProductClick,
  handleProductChoiceClick,
  handleSpecialProductClick,
  handleChangeCandidate,
  handleCloseProductChoice,
  setIsDisplayProductDetail,
  setSelectedCandidate,
}) => {
  const { data: product } = useGetProductQuery(
    {
      productId: primaryCandidate?.product_id ?? 0,
    },
    {
      skip: !primaryCandidate?.product_id,
    }
  );
  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={reset}
        PaperProps={{
          sx: {
            overflowY: 'visible',
          },
        }}
      >
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          width={350}
          height="100%"
          position="relative"
          paddingBottom={
            bottomDrawerOpen ? `${bottomDrawerHeight}px` : undefined
          }
        >
          <Box
            component="div"
            sx={{
              px: 2,
              pb: 2,
              borderBottom: '1px solid #DDDDDD',
              position: 'sticky',
            }}
          >
            {selectedItem && (
              <SelectedProduct
                product={product?.product}
                referenceImage={referenceImage}
                handleClose={() =>
                  handleClose('cancel', productIdFrom, 'unknown')
                }
                handleOpenReferenceImagePreview={
                  handleOpenReferenceImagePreview
                }
                face={selectedItem.item}
                isProductUnknown={isUnknown}
                setSelectedProductZoomIn={setSelectedProductZoomIn}
              />
            )}
          </Box>
          <Box
            component="div"
            sx={{
              overflow: 'auto',
              height: '100%',
              px: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {selectedItem?.item?.is_unknown && (
              <Box component="div" mb={2}>
                <HighestScoreCandidate
                  products={products}
                  primaryCandidate={primaryCandidate}
                  productCandidates={productCandidates}
                  selectedCandidate={selectedCandidate}
                  handleProductClick={handleProductClick}
                />
              </Box>
            )}
            <ProductCandidates
              products={products}
              primaryCandidate={primaryCandidate}
              productCandidates={productCandidates}
              productCandidatesLength={productCandidatesLength}
              selectedCandidate={selectedCandidate}
              handleProductClick={handleProductClick}
              face={selectedItem?.item}
            />
            {productCandidatesLength < 1 && ( //NOTE: 最も確信度の高い商品が1番目に入るため
              <Box component="div" height={84} mb={1}>
                <CandidateEmptyResult title="訂正候補がありません" />
              </Box>
            )}

            <HistoryCandidates
              historyCandidates={historyCandidates}
              selectedCandidate={selectedCandidate}
              handleProductClick={handleProductClick}
            />

            {(historyCandidates?.length ?? 0) <= 0 && (
              <Box component="div" height={84} mb={1}>
                <CandidateEmptyResult title="最近訂正した商品がありません" />
              </Box>
            )}

            <Box component="div" mb={2} sx={{ padding: '16px 0px 0px 0px' }}>
              <ProductChoiceCard handleClick={handleProductChoiceClick} />
            </Box>
            <Box component="div">
              <UnknownCard
                selectedUnknown={selectedUnknown}
                selectedItem={selectedItem}
                handleClick={handleSpecialProductClick}
              />
            </Box>
          </Box>
        </Box>
        {bottomDrawerOpen && (
          <BottomDrawer
            handleClose={handleCloseProductChoice}
            handleChangeCandidate={handleChangeCandidate}
            isDisabled={
              !isUnknown &&
              (isLoading ||
                selectedCandidate?.product_id ===
                  selectedItem?.item.primary_candidate?.product_id)
            }
            buttonText="選択"
          />
        )}
      </Drawer>
      <ProductChoiceDrawer
        isResetData={isResetData}
        open={selectedProductChoice}
        setCloseDrawer={handleCloseProductChoice}
        handleProductClick={handleProductClick}
        handleChangeCandidate={handleChangeCandidate}
        selectedItemId={primaryCandidate?.product_id}
        candidateItem={selectedCandidate}
        isDisplayProductDetail={isDisplayProductDetail}
        setIsDisplayProductDetail={setIsDisplayProductDetail}
        setSelectedCandidate={setSelectedCandidate}
        isLarger={isLarger}
        realogramProductsCategories={realogramProductsCategories}
      />
    </>
  );
};
